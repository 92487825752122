


.Toastify__toast-container--bottom-right {

    margin-bottom : 60vh;
}

.loading__btn {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  height: 3em;
  padding: 0.5em 5em;
  border: none;
  color: white;
  text-decoration: none;
  background: rgba(29, 38, 36, 1);
  border-radius: 40px;
  max-width: 18em;
    
    
    }
